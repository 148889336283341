<template>
  <div>
    <v-row> </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-title class="mb-0 pb-0 justify-center">
          {{ $lang("CHOOSEEXAM") }}
        </v-card-title>
        <v-card-text class="mt-0 pt-0 text-center">
          {{ $lang("CHOOSEEXAMFROMBELOW") }}
        </v-card-text>
      </v-col>
    </v-row>
    <v-row dense class="d-flex text-center justify-center">
      <v-col v-for="item in items" :key="item.id" cols="6" md="3">
        <v-card
          @click="$router.push({ name: 'exam', params: { id: item.id } })"
          elevation="0"
          style="overflow: hidden"
          height="160"
          class="
            ma-0
            pa-0
            pt-1
            d-flex
            flex-column
            justify-center
            align-center
            rounded-lg
          "
        >
          <v-avatar size="70" class="mb-2" tile>
            <v-img
              :src="
                item.banner_square
                  ? item.banner_square
                  : 'https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&h=400&q=80'
              "
            />
          </v-avatar>
          <div class="title">
            {{
              isHindi
                ? item.title_hi
                  ? item.title_hi
                  : item.title
                : item.title
            }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";

export default {
  name: "exams",
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
    isDark() {
      return this.$store.getters.isThemeDark;
    },
  },
  async mounted() {},
  methods: {
    fetchItems() {
      bus.$emit("showWait");
      axios
        .get("prep/exams/public")
        .then((res) => {
          let items = res.data.data.filter(
            (el) => !el.parent_id || el.parent_id == ""
            // el.children &&
            // el.children.length > 0
          );

          this.items = items;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>

<style>
.textShortDesc {
  /*Here we have determined the max number of lines.*/
  display: block; /* or inline-block */
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.2em; /*max-height/line-height=rowCount */
  line-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>